import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { Redirect } from 'react-router-dom';


import evidency2 from '../assets/evidency2.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import moment from 'moment';
import profile from '../assets/svg/profile.svg';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import Form from '../components/forms/calendarForm';
import RightSidebar from '../components/rightSidebar';
import ok from '../assets/svg/ok.svg';
import noImage from '../assets/no-image.png';

import moreIcon from '../assets/svg/more-icon.svg';
import ReactPaginate from 'react-paginate';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button, Input
} from 'reactstrap';

import { API_ENDPOINT } from '../constants';

var striptags = require('striptags');

class CalendarPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            ...props.initialData
        };
    }
    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })

        this.get();

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }

        if (prevProps.user != this.props.user) {
            this.get()
        }

    }
    get = () => {
        // if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        if (this.props.user) {
            fetch(`https://interni-api.euroexpress.ba/absence/${this.props.user._id}`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    //   'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    page: this.state.page,
                    search: this.state.search
                })
            }).then(res => res.json()).then((result) => {
                // console.log(result);
                this.setState({
                    data: result.items,
                    total: result.total
                })
            })
        }



    }


    submit(data) {
        console.log(data);
        // data.startTS = 1610280873;
        // data.endTS = 1610712873;
        data.startTS = Math.floor(data.date.range1.startDate.getTime() / 1000);

        data.endTS = Math.floor(data.date.range1.endDate.getTime() / 1000);


        if (this.props.user) {
            data.user = this.props.user._id;

            this.setState({
                formLoading: true
            }, () => {

                fetch('https://interni-api.euroexpress.ba/absence', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                    },
                    body: JSON.stringify(
                        data
                    )
                }).then((res) => res.json()).then((result) => {
                    this.setState({
                        //formLoading: null,
                        formDone: true
                    })
                    this.get();
                    this.setState({ messageSent: true })
                    setTimeout(() => {
                        this.setState({ messageSent: false });
                        window.location.reload(false)
                    }, 2000);
                })
            })
        }
    }
    deleteRequset = (id) => {
        // console.log(id)


        fetch('https://interni-api.euroexpress.ba/absence/delete/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({
                //formLoading: null,
                formDone: true
            })
            this.get();
            this.setState({ deleteModal: null })

        })
    }
    getParams = () => {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        return params;

    }


    render() {
        let params = this.getParams()

        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix blue-bg" style={{ paddingRight: 0 }}>
                                    <div className="sugestion-container">
                                        <div className="sugestion-title">
                                            <div className="sugestion-title-left">
                                                <h2>Kalendar odsutnih</h2>
                                            </div>
                                            <div className="breadcumb">
                                                <span>
                                                    <Link to="/">Početna</Link>&nbsp;/&nbsp;<a>Ljudski resursi</a>&nbsp;/&nbsp;<a href="/kalendar" className="b-active">Kalendar odsutnih</a>
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="calendar-row">
                                            <div className="calendar-left">
                                                <p>Izaberite datume od kada
                                                zelite da posaljete zahtjev
                                        za odsustvo.</p>
                                                <img src={evidency2} alt="" />
                                            </div>
                                            <div className="calendar-right">
                                                <div id="calendar"></div>
                                            </div>
                                        </div> */}
                                        <Form onSubmit={this.submit} />


                                        {/* <form className="sugestion-form">
                                            <label for="title">Naslov poruke</label> <br />
                                            <input type="text" name="title" id="title" placeholder="Zahtjev za bolovanje"/> <br />
                                                <label for="message">Poruka</label> <br />
                                                <textarea name="message" id="message" cols="30" rows="10" placeholder="Zelio bih da uzmem bolovanje..."></textarea>
                                                <button type="submit">Posalji zahtjev <span><img src="assets/arrows-right.png" alt="" /></span></button>
                                        </form> */}
                                        {/*
                                        <div className="requirement">
                                            <div className="requirement-title">
                                                <h6>Vaši zahtjevi</h6>
                                            </div>
                                            {
                                                this.state.data && this.state.data.map((item, idx) => {
                                                    return (
                                                        <div className="requirment-row">
                                                            <span>{item.title}</span>
                                                            <span>{moment.unix(item.startTS).format('DD.MM.YYYY.')} - {moment.unix(item.endTS).format('DD.MM.YYYY.')}</span>
                                                            {
                                                                item.status == 0 ?
                                                                    <span className="pending">Na cekanju</span>
                                                                    :
                                                                    item.status == 1 ?
                                                                        <span className="approved">Odobreno</span>
                                                                        :
                                                                        <span className="denied">Odbijeno</span>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                             <div className="requirment-row">
                                                <span>Zahtjev za bolovanje</span>
                                                <span>10.01.2021. - 13.01.2021.</span>
                                                <span>Odbijeno</span>
                                            </div>
                                            <div className="requirment-row">
                                                <span>Zahtjev za bolovanje</span>
                                                <span>10.01.2021. - 13.01.2021.</span>
                                                <span>Odbijeno</span>
                                            </div> 
                                        </div>
                                        */}


                                        <div className="table-container">
                                            <div className='absence-filter-wrap'>
                                                <p>Pretraga:</p>
                                                <Input value={this.state.search} onChange={(e) => this.setState({ search: e.target.value }, () => {
                                                    if (params && params.page && Number(params.page) > 0) {
                                                        this.updateParam('page', '0')
                                                    } else {
                                                        this.get()
                                                    }
                                                })} />
                                            </div>

                                            <table className="fl-table">
                                                <thead>
                                                    <tr>
                                                        <th>Ime i prezime</th>
                                                        <th>Radno mjesto</th>
                                                        <th>Radna jedinica</th>
                                                        <th>Vrijeme odsustva</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        this.state.data && this.state.data.map((item, idx) => {
                                                            return (
                                                                <tr>
                                                                    <td><div className="first-td"><span>{item.firstName} {item.lastName}</span></div></td>
                                                                    <td>{item.workPlace}</td>
                                                                    <td>{item.workUnit}</td>
                                                                    <td>
                                                                        {
                                                                            moment.unix(item.startTS).format('DD.MM.YYYY.') == moment.unix(item.endTS).format('DD.MM.YYYY.') ?
                                                                                <>{moment.unix(item.endTS).format('DD.MM.YYYY.')}</>
                                                                                :
                                                                                <>{moment.unix(item.startTS).format('DD.')} - {moment.unix(item.endTS).format('DD.MM.YYYY.')}</>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            this.props.user._id == item.user ?
                                                                                <div onClick={() => this.setState({ deleteModal: item._id })}><Isvg src={moreIcon} /></div>
                                                                                :
                                                                                null
                                                                        }

                                                                    </td>
                                                                </tr>
                                                                // <div className="requirment-row">
                                                                //     <span>{item.title}</span>
                                                                //     <span>{moment.unix(item.startTS).format('DD.MM.YYYY.')} - {moment.unix(item.endTS).format('DD.MM.YYYY.')}</span>
                                                                //     {
                                                                //         item.status == 0 ?
                                                                //             <span className="pending">Na cekanju</span>
                                                                //             :
                                                                //             item.status == 1 ?
                                                                //                 <span className="approved">Odobreno</span>
                                                                //                 :
                                                                //                 <span className="denied">Odbijeno</span>
                                                                //     }
                                                                // </div>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            {
                                                this.state.total / 10 > 1 ?
                                                    <Row>
                                                        <Col lg="12">
                                                            <ReactPaginate
                                                                previousLabel={''.translate(this.props.lang)}
                                                                nextLabel={''.translate(this.props.lang)}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={this.state.total / 10}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={2}
                                                                onPageChange={(page) => { this.updateParam('page', page.selected); }}
                                                                // onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                hrefBuilder={(page) => { return `?page=${page}` }}
                                                                forcePage={Number(this.state.page)}
                                                            // hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                                            //forcePage={this.state.page}
                                                            />

                                                        </Col>

                                                    </Row>

                                                    :

                                                    null
                                            }

                                        </div>




                                    </div>


                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>
                    {this.state.messageSent ? (
                        <Modal
                            isOpen={this.state.messageSent}
                            toggle={() =>
                                this.setState({
                                    messageSent: !this.state.messageSent,
                                })
                            }
                            style={{ height: '100px' }}
                        >
                            <ModalHeader
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>

                                    {"Uspješno ste poslali zahtjev za odsustvo..."}
                                    <Isvg src={ok} style={{ marginTop: 20, marginBottom: 20 }} />

                                </div>
                            </ModalHeader>


                        </Modal>
                    ) : null}
                    {this.state.deleteModal ? (
                        <Modal
                            isOpen={this.state.deleteModal}
                            toggle={() =>
                                this.setState({
                                    deleteModal: !this.state.deleteModal,
                                })
                            }
                        >
                            <ModalHeader
                                style={{ width: '100%' }}
                            >
                                <div style={{ alignItems: 'center', marginTop: 20, width: '100%' }}>

                                    {"Da li želite da otkažete zahtjev?"}
                                    {/* <Isvg src={ok} style={{ marginTop: 20 }} /> */}

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 20, width: '100%' }}>
                                    <Button color="primary" style={{ backgroundColor: "#2F629C", width: '100px' }} onClick={() => this.deleteRequset(this.state.deleteModal)}>Da</Button>
                                    <Button color="primary" style={{ backgroundColor: "#2F629C", marginLeft: 20, width: '100px' }} onClick={() => this.setState({ deleteModal: null })}>Ne</Button>
                                </div>
                            </ModalHeader>


                        </Modal>
                    ) : null}
                </section>

            </div>
        );
    }
}

export default Page(CalendarPage);